/* You can add global styles to this file, and also import other style files */
.medium-icon {
  width: 48px !important;
  height: 48px !important;
  font-size: 48px !important;
}

.feather-icon-success {
  color: rgba(24, 206, 15, 0.8);
  display: inline !important;
  .feather {
    height: 20px !important;
    width: 20px !important;
  }
}
.feather-icon-disabled {
  color: rgba(141, 141, 141, 0.8);
  display: inline !important;
  .feather {
    height: 20px !important;
    width: 20px !important;
  }
}
.feather-icon-error {
  color: rgba(255, 0, 0, 0.8);
  display: inline !important;
  .feather {
    height: 20px !important;
    width: 20px !important;
  }
}
